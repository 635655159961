import Vue from 'vue'

export const DRAG_PENDING_EVENT = 'drag-pending'

export const MODAL_OPEN_EVENT = 'open'
export const MODAL_CLOSE_EVENT = 'close'
export const MODAL_SWITCH_EVENT = 'switch'
export const MODAL_REVERSE_SWITCH_EVENT = 'reverse-switch'
export const MODAL_RETURN_TO_ACTIONS_LIST_EVENT = 'return-to-actions-list'

export const MODAL_LOADING_EVENT = 'loading'
export const MODAL_LOADING_COMPLETE_EVENT = 'loading-complete'

export const IMAGE_MODAL_OPEN_EVENT = 'image-modal-open'

export const VIDEO_PLAY_EVENT = 'video-play'
export const VIDEO_RESUME_EVENT = 'video-resume'
export const VIDEO_PAUSE_EVENT = 'video-pause'
export const VIDEO_ENDED_EVENT = 'video-ended'

export const AUDIO_PLAY_EVENT = 'audio-play'
export const AUDIO_RESUME_EVENT = 'audio-resume'
export const AUDIO_PAUSE_EVENT = 'audio-pause'
export const AUDIO_ENDED_EVENT = 'audio-ended'

export const ARRANGEMENT_GROUPS_UPDATED = 'arrangement-groups-updated'

export const NOTIFICATIONS_MODAL_OPEN_EVENT = 'notifications-modal-open'

export const PROGRESS_MODAL_OPEN_EVENT = 'progress-modal-open'

export const TOOL_USE_EVENT = 'tool-use'

export const ENABLE_REVIEW_EVENT = 'enable-review'

export const SUBMITTED_QUESTION_ENABLED_EVENT = 'submitted-question-enabled'

export const NAVIGATE_EVENT = 'page-navigate'
export const VIEW_EVENT = 'page-view'

export const QUESTION_PAGE_UPDATE_EVENT = 'question-page-update'
export const QUESTION_PAGE_UPDATE_PARTIAL_EVENT = 'question-page-update-partial'
export const QUESTION_PAGE_NAVIGATE_EVENT = 'question-page-navigate'
export const QUESTION_PAGE_NAVIGATE_PARTIAL_EVENT = 'question-page-navigate-partial'

export const INTERACTION_IMAGE_SCALED = 'interaction-image-scaled'

export const PARTIAL_CONTENT_WILL_UPDATE_EVENT = 'content-will-update'

export const RESULT_MODAL_UPDATE_EVENT = 'result-modal-update'

export const SETUP_CONTENT_ELEMENTS = 'setup-content-elements'

export const RESIZE_EVENT = 'resize'

export const GLIDE_CAROUSEL_MOVE = 'glide-carousel-move'
export const GLIDE_CAROUSEL_NAVIGATE = 'glide-carousel-navigate'

export const SELECT_TAB_EVENT = 'select-tab'

export const ASSIGNMENTCARD_OPEN = 'assignment-card-open'

export const EventBus = new Vue()
